/* Navigation
================================================== */
.nav {
  width: auto;
  margin: 0;
  padding: 0;

  ul {
    display: none;
    padding: 0;
  }

  li {
    display: flex;
    position: relative;
    margin-bottom: 0;
    padding: 0;
    text-align: center;
    list-style: none;
    @include bp_max(mlarge) {
      width: auto;
      text-align: center;
    }
    &:last-of-type {
      .mod-menu__sub {
        @include bp(xlarge) {
          right: 0;
        }
        li {
          @include bp(xlarge) {
            text-align: right;
          }
        }
      }
    }
  }

  a {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1rem;
    color: $secondary-colour;
    font-size: 1rem;
    text-decoration: none;
    text-transform: uppercase;
    line-height: 1.2;
    font-weight: 600;
    @include bp(xlarge) {
      font-size: 0.8rem;
    }
    @include bp(xxxlarge) {
      font-size: 0.9rem;
    }
    &.button {
      margin: 1rem 0 0 0;
      padding: 1rem 1.5rem;
      font-size: 0.9rem;
      color: $white !important;
      @include bp(xlarge) {
        margin: 0;
        font-size: 0.8rem;
      }
      @include bp(xxlarge) {
        font-size: 0.9rem;
      }
    }
  }

  a:hover,
  .current > a,
  .active > a {
    color: $primary-colour;
    &:after {
      border-color: $primary-colour transparent transparent transparent !important;
    }
  }

  .parent {
    @include bp_max(xlarge) {
      flex-direction: column;
    }
    > a {
      position: relative;

      &:after {
        content: '';
        display: inline-flex;
        width: 0;
        height: 0;
        margin-left: 0.5rem;
        border-style: solid;
        border-width: 5px 4px 0 4px;
        border-color: $secondary-colour transparent transparent transparent;
        transition: $global-transition;
      }
    }

    ul {
      display: none;
      flex-flow: column nowrap;
      justify-self: left;
      list-style: none;
      width: 100%;
      min-width: 180px;
      margin: 0;
      padding: 0;

      @include bp(xlarge) {
        position: absolute;
        top: 100%;
        align-items: flex-start;
        background-color: rgba(255, 255, 255, 0.8);
      }

      li {
        width: 100%;
        @include bp_max(xlarge) {
          justify-content: center;
        }
      }

      a {
        width: 100%;
        font-size: 0.8rem;
        @include bp(xlarge) {
          justify-content: left;
          text-align: left;
        }
      }
    }
  }
}

/* Main Menu
================================================== */
.nav--main {
  display: flex;
  background-color: rgba(255, 255, 255, 0.8);
  @include bp(xxlarge) {
    margin-left: auto;
  }
  .nav {
    display: none;
    @include bp(xxlarge) {
      display: inline-flex;
      flex-flow: row nowrap;
      justify-content: space-between;
    }
    .parent {
      &:hover {
        ul {
          display: flex;
        }
      }
    }
  }
  .mod-menu__sub {
    background-color: $primary-colour;
  }
  .shrink & {
    a {
      @include bp(medium) {
        font-size: 0.8rem;
      }
    }
  }
  .nav-active & {
    .nav {
      background-color: $primary-colour;
    }
  }
}

/* Nav Overlay
================================================== */
body.nav-active {
  overflow: hidden;
  scrollbar-gutter: stable;
}
.nav--overlay {
  display: none;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: center;
  position: fixed;
  z-index: 100;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: $white;
  .nav-active & {
    display: flex;
  }
  .nav__inner {
    width: 100%;
    padding-top: 2rem;
    padding-bottom: 12rem;
    overflow-y: auto;
    scrollbar-gutter: stable;
    //padding-left: calc(100vw - 100%);
  }
  .nav {
    width: 100%;
    margin-bottom: 2rem;
    li {
      justify-content: center;
      flex-wrap: wrap;
    }
    a {
      font-size: 1rem !important;
      &.button {
        margin: 1rem 0 0 0 !important;
        font-size: 0.9rem !important;
      }
    }
    ul.active {
      display: flex;
    }
    .parent {
      ul {
        position: static !important;
      }
      &:hover {
        ul {
          @include bp(mlarge) {
            display: flex;
          }
        }
      }
    }
    .mod-menu__sub {
      a {
        justify-content: center;
        text-align: center;
        font-size: 0.8rem !important;
      }
    }
  }
  .nav-button-wrap {
    margin-left: auto;
    margin-right: -0.625rem;
    @include bp(medium) {
      margin-right: 0.9375rem;
    }
  }
  .nav-button {
    margin: 1.5rem 1rem 1.5rem auto;
    @include bp(medium) {
      margin-right: 1rem;
    }
    @include bp(xlarge) {
      margin-right: 0rem;
    }
    @include bp(xxlarge) {
      margin-right: 1rem;
    }
    .shrink & {
      margin-top: 0.5rem;
      margin-bottom: 0.5rem;
    }
  }
  .main-buttons {
    display: flex;
    justify-content: center;
    flex-direction: column;
    @include bp(medium) {
      align-items: center;
    }
    p {
      margin: 0;
    }
    .button {
      min-width: 240px;
      margin: 0.5rem 0;
      @include bp_max(medium) {
        width: 100%;
      }
    }
  }
  .social {
    margin-top: 2rem;
    text-align: center;
    ul {
      margin: auto;
    }
  }
}

/* Hamburger / Menu
================================================== */
.nav-button {
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
  z-index: 1;
  margin: 0;
  padding: 1rem;
  background: transparent !important;
  outline: 0;
  cursor: pointer;
  @include bp(xlarge) {
    padding-right: 0;
  }

  p {
    margin: 0;
    padding: 0 0.75rem 0 0;
    color: $secondary-colour;
  }

  span,
  span:before,
  span:after {
    content: '';
    display: block;
    position: absolute;
    right: 0;
    width: 2em;
    height: 4px;
    background: $secondary-colour;
    @include border-radius(1px);
    @include transition($global-transition);
  }

  span {
    position: relative;
    &:before {
      top: -10px;
    }
    &:after {
      top: auto;
      bottom: -10px;
    }
  }

  .nav-active & {
    span {
      background: transparent;
      &:before,
      &:after {
        top: 0;
        background: $secondary-colour;
      }
      &:before {
        @include rotate(45);
      }
      &:after {
        @include rotate(-45);
      }
    }
  }

  @include bp(xxlarge) {
    display: none;
  }
}
